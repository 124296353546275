import React, { PropsWithChildren, useState } from 'react';

import { Icon } from '../../01-atoms';
import { Card, CardProps } from '../card/Card';

export interface ExpandableCardProps extends CardProps, PropsWithChildren {
  content: React.ReactNode;
}

export const ExpandableCard = (props: ExpandableCardProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Card
      as="button"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(!open);
      }}
      {...props}
      className="w-full text-left"
    >
      <div>
        <div className="flex items-center justify-between">
          <div className="mr-2 flex items-center">{props.content}</div>
          <div>
            <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>
        {open ? <div className="pt-4">{props.children} </div> : null}
      </div>
    </Card>
  );
};
