import { getPlatformLink } from '@firi/shared';
import { Card } from '@firi/ui-web';
import { t, Trans } from '@lingui/macro';
import clsx from 'clsx';
import ContentWrapper from 'components/ContentWrapper';
import { MenuDrawerProps } from 'components/MenuDrawer';
import { getCookie } from 'cookies-next';
import { AnimatePresence } from 'framer-motion';
import { sendGTMEvent } from 'helpers/sendGTMEvent';
import { useScroll } from 'hooks/useScroll';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';

import { headerItems } from '../constants/nav';
import { alternateSlug, getLocalizedUrl } from '../helpers/url';
import LogoBlue from '../shared/svg/logo-blue.svg';
import Logo from '../shared/svg/logo-white.svg';
import { Button } from './Button';
import { LanguageSelect } from './LanguageSelect';
import { Typography } from './Typography';

const MenuDrawer = dynamic<MenuDrawerProps>(() =>
  import('components/MenuDrawer').then((mod) => mod.MenuDrawer),
);

const HoverPopoverWithNoSSR = dynamic(
  () => import('./HoverPopover').then((mod) => mod.HoverPopover),
  { ssr: false },
);

export interface HeaderProps {
  alternateSlugs: alternateSlug;
}

export const Header = ({ alternateSlugs }: HeaderProps) => {
  const router = useRouter();
  const currentPath = router.asPath;
  const scroll = useScroll();
  const [showMenuDrawer, setMenuDrawer] = useState<boolean>(false);

  const handleDrawer = () => setMenuDrawer(!showMenuDrawer);

  const isIndex = router.pathname === '/';
  const dummyLoggedIn = getCookie('session');

  useEffect(() => {
    if (!showMenuDrawer) return;

    router.events.on('routeChangeComplete', handleDrawer);

    return () => {
      router.events.off('routeChangeComplete', handleDrawer);
    };
  }, [router.events, showMenuDrawer]);

  return (
    <>
      <header
        className={clsx(
          ' z-[9] sticky top-0 transition-transform',
          isIndex ? 'bg-white' : 'bg-firi-blue',
          {
            '-translate-y-36': scroll.direction === 'down' && scroll.position > 100,
          },
        )}
      >
        <ContentWrapper className="flex items-center py-4 ">
          <div className="flex-1 flex items-center space-x-14">
            <Link href="/">
              <a
                className="flex w-[80px] h-[24px] md:w-[132px] md:h-[36px]"
                aria-label={t`Home`}
                onClick={() =>
                  sendGTMEvent({
                    event_action: `Click logo`,
                    event_label: 'Logo',
                    event_category: 'Interactions',
                  })
                }
              >
                {isIndex ? (
                  <LogoBlue className="w-full h-full" />
                ) : (
                  <Logo className="w-full h-full" />
                )}
              </a>
            </Link>
            {router.pathname !== '/campaign/[slug]' && (
              <nav className="hidden lg:flex space-x-6">
                {headerItems.map((item) => {
                  return (
                    <HoverPopoverWithNoSSR
                      key={item.title}
                      label={item.title}
                      closePopover={scroll.direction === 'down'}
                      variant={isIndex ? 'blue' : 'white'}
                    >
                      <Card className="flex flex-col min-w-[280px] p-6 shadow-md space-y-4">
                        {item.subItems.map((sItem) => {
                          const localizedUrl = getLocalizedUrl(
                            sItem.path[router.locale || 'en'],
                            router.locale,
                            alternateSlugs,
                          );

                          if (!localizedUrl)
                            return <Fragment key={`${sItem.path}-${sItem.title}`}></Fragment>;
                          const isActive = currentPath === localizedUrl;

                          return (
                            <Link
                              key={`${sItem.path}-${sItem.title}`}
                              href={localizedUrl}
                              locale={router.locale}
                              passHref
                            >
                              <Typography
                                as="a"
                                size="sm"
                                color={isActive ? 'blue' : '1'}
                                className={clsx(
                                  'w-max box-content border-b border-white hover:text-firi-blue hover:border-firi-blue hover:font-medium',
                                  isActive && 'border-firi-blue font-medium',
                                )}
                                onClick={() =>
                                  sendGTMEvent({
                                    event_action: `Menu click`,
                                    event_label: sItem.title,
                                    event_category: 'Interactions',
                                  })
                                }
                              >
                                <Trans id={sItem.title} />
                              </Typography>
                            </Link>
                          );
                        })}
                      </Card>
                    </HoverPopoverWithNoSSR>
                  );
                })}
              </nav>
            )}
          </div>
          <div className="hidden lg:flex">
            {dummyLoggedIn ? (
              <a href={getPlatformLink({ link: 'login' })} className="inline-block mr-4">
                <Button
                  skin="white-primary"
                  aria-label="Back to logged in"
                  eventAction="Login"
                  eventCategory="Identification"
                >
                  <Trans>Back to logged in</Trans>
                </Button>
              </a>
            ) : (
              <>
                {
                  <a
                    href={getPlatformLink({ link: 'login' })}
                    className="inline-block mr-4"
                    onClick={() =>
                      sendGTMEvent({
                        event_action: 'Login click',
                        event_label: 'Login',
                        event_category: 'Identification',
                      })
                    }
                  >
                    <Button
                      skin="white-primary"
                      aria-label="Login"
                      eventAction="Login"
                      eventCategory="Identification"
                    >
                      <Trans>Sign in</Trans>
                    </Button>
                  </a>
                }

                <a className="inline-block mr-4" href={getPlatformLink({ link: 'signup' })}>
                  <Button
                    eventAction="Get started"
                    aria-label="Get started"
                    eventCategory="Identification"
                    skin={isIndex ? 'primary' : 'white-primary'}
                  >
                    <Trans>Get started</Trans>
                  </Button>
                </a>
              </>
            )}

            <div>
              <LanguageSelect
                alternateSlugs={alternateSlugs}
                variant={isIndex ? 'white' : 'blue'}
              />
            </div>
          </div>

          {router.pathname !== '/campaign/[slug]' && (
            <div className="lg:hidden">
              <Button
                skin={isIndex ? 'secondary' : 'primary'}
                className="border-0"
                onClick={handleDrawer}
                aria-label="Menu"
                size="md"
                icon="hamburger-menu"
              />
            </div>
          )}
        </ContentWrapper>
      </header>
      <AnimatePresence>
        {showMenuDrawer && (
          <MenuDrawer handleDrawer={handleDrawer} alternateSlugs={alternateSlugs} />
        )}
      </AnimatePresence>
    </>
  );
};
