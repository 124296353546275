import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { MarketsQueryQuery, MarketsQueryQueryVariables, PnlPeriod } from '../gqlTypes';

const Market = gql`
  fragment Market on Market {
    symbol
    lastPrice
    change
    volume
    baseCurrency {
      symbol
      name
    }
    quoteCurrency {
      symbol
      name
    }
    marketType
    hasAdvanced
  }
`;

// TODO: remove includeOtc param when everything has support fot OTC
export const AVAILABLE_MARKETS_QUERY = gql`
  query MarketsQuery($includeOtc: Boolean, $period: PNLPeriod!) {
    markets(includeOtc: $includeOtc) {
      ...Market
      priceHistory(period: $period) {
        change: change
        data {
          price
          date
        }
      }
    }
  }
  ${Market}
`;

export const useMarkets = (params?: {
  includeOtc: boolean;
  period?: PnlPeriod;
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const result = useQuery<MarketsQueryQuery, MarketsQueryQueryVariables>(AVAILABLE_MARKETS_QUERY, {
    variables: {
      includeOtc: !!params?.includeOtc,
      period: params?.period || PnlPeriod.Day,
    },
    fetchPolicy: params?.fetchPolicy,
  });

  if (!result.data) return result;

  const markets = result.data.markets.map((m) => ({
    ...m,
    lastPrice: [...m.priceHistory.data].pop()?.price,
    change: m.priceHistory.change,
  }));

  const data: MarketsQueryQuery = { ...result.data, markets: markets };

  return { ...result, data };
};
