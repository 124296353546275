import { PnlPeriod } from './../gql/gqlTypes';

const timePeriodToDays = (timePeriod: PnlPeriod): 1 | 7 | 30 | 90 | 365 | 10000 => {
  switch (timePeriod) {
    case PnlPeriod.Day:
      return 1;
    case PnlPeriod.Week:
      return 7;
    case PnlPeriod.Month:
      return 30;
    case PnlPeriod.ThreeMonth:
      return 90;
    case PnlPeriod.Year:
      return 365;
    case PnlPeriod.All:
      return 10000;
    default:
      return 30;
  }
};

export { timePeriodToDays };
