import { useEffect, useState } from 'react';

import { FormType, KycFormQuery, KycMultipleAnswerInput, KycQuestion } from './../gql/gqlTypes';
import { useKycMultipleAnswers } from './../gql/mutations/useKycMultipleAnswers';
import { useKycForm } from './../gql/queries/useKycForm';

interface Params {
  formType: FormType;
  onVoluntarySuccess?: () => void;
  onDeclarationSuccess?: () => void;
  onMandatorySuccess?: () => void;
  onError: (e?: any) => void;
}

export type AnswerInput = {
  answer: string;
  subsequentquestionAnswerId: number | null | undefined;
  answerId: number | null | undefined;
};

type Screen = 'intro' | 'questions' | 'file-upload' | 'summary' | 'edit-question' | 'completed';

export type KycFormState = {
  screen: Screen;
  setScreen: (s: Screen) => void;
  answers: Answer[];
  setAnswers: (a: Answer[]) => void;
  currentQuestion?: KycQuestion;
  prevQuestionTitle: string;
  formSubmitted: boolean;
  setCurrentQuestion: (q: KycQuestion) => void;
  handleAnswer: (questionId: number) => (params: AnswerInput) => Promise<void>;
  handleEditAnswer: (input: AnswerInput) => void;
  handleSubmit: (answers: Answer[]) => Promise<void>;
  progress: number;
  maxProgress: number;
  editQuestion: (q: KycQuestion) => void;
  goBack: () => void;
  formId?: number;
  data?: KycFormQuery;
  loading: boolean;
  prologue?: string | null | undefined;
  formLoading: boolean;
};
export type Answer = KycMultipleAnswerInput & {
  answerId?: number | null;
  prevAnswerTitle?: string | null;
  fromQuestionId?: number;
};

export const useKycFormState = (params: Params): KycFormState => {
  const { formType } = params;
  const [currentQuestion, setCurrentQuestion] = useState<KycQuestion>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [screen, setScreen] = useState<Screen>(
    formType === FormType.Mandatory ? 'intro' : 'questions',
  );
  const [loading, setLoading] = useState(false);

  const { data, loading: formLoading } = useKycForm({ formType: formType });
  const [setKycAnswers] = useKycMultipleAnswers();

  useEffect(() => {
    if (data?.kycForm?.questions && !answers.length) {
      const firstQuestion = data?.kycForm.questions.find((c) => c.order === 1);

      setCurrentQuestion(firstQuestion);
      setAnswers(
        data?.kycForm.questions.map((q) => ({
          questionAnswerId: q.id,
          answer: '',
          answerId: null,
        })) ?? [],
      );
    }
  }, [data]);

  const handleSubmit = async (answers: Answer[]) => {
    if (!data?.kycForm?.id) return;
    setLoading(true);

    const answersWithTransKey: KycMultipleAnswerInput[] = answers.map((a) => {
      const qa = data.kycForm?.questions.find((q) => q.id === a.questionAnswerId);
      const answerTranslationKey = qa?.answers.find(
        (qaAnswer) => qaAnswer.id === a.answerId,
      )?.translationKey;

      return {
        questionAnswerId: a.questionAnswerId,
        answer: answerTranslationKey || a.answer,
        answerId: a.answerId,
      };
    });

    await setKycAnswers({
      variables: {
        input: {
          formServedId: data?.kycForm.id,
          language: 'NB',
          answers: answersWithTransKey,
        },
      },
    })
      .then(async (r) => {
        if (!r.data?.sendMultipleAnswer.id) {
          params.onError();
          return;
        }
        if (formType === FormType.Declaration && params.onDeclarationSuccess) {
          params.onDeclarationSuccess();
        } else if (formType === FormType.Voluntary && params.onVoluntarySuccess) {
          params.onVoluntarySuccess();
        } else if (formType === FormType.Mandatory && params.onMandatorySuccess) {
          params.onMandatorySuccess();
          setScreen('completed');
        } else {
          setScreen('completed');
        }
      })
      .catch((e) => {
        setLoading(false);
        params.onError(e);
      })
      .finally(() => setLoading(false));
  };

  const handleAnswer = (questionId: number) => async (params: AnswerInput) => {
    const { answer, subsequentquestionAnswerId, answerId } = params;

    const prevAnswer = answers.find((a) => a.questionAnswerId === questionId);
    const prevSubsequentAnswerId = currentQuestion?.answers.find(
      (a) => a.id === prevAnswer?.answerId,
    )?.subsequentquestionAnswerId;

    let strippedAnswers: Answer[] = [...answers];
    if (!subsequentquestionAnswerId && prevSubsequentAnswerId) {
      strippedAnswers = recursivelyRemoveAnswer(prevSubsequentAnswerId, strippedAnswers);
    }

    const subsequentQuestion = data?.kycForm?.questions.find((q) => {
      return q.id === subsequentquestionAnswerId;
    });

    let nextQuestion = null;
    if (subsequentQuestion) {
      nextQuestion = subsequentQuestion;
    } else {
      nextQuestion = data?.kycForm?.questions.find(
        (q) => q.order === Math.floor(currentQuestion?.order ?? 0) + 1,
      );
    }
    const newAnswers: Answer[] = strippedAnswers.map((a) => {
      if (a.questionAnswerId === questionId) {
        return {
          ...a,
          questionAnswerId: questionId,
          answer,
          answerId: answerId,
        };
      } else if (a.questionAnswerId === subsequentquestionAnswerId) {
        return { ...a, prevAnswerTitle: answer, fromQuestionId: questionId };
      } else if (a.questionAnswerId === nextQuestion?.id) {
        return { ...a, fromQuestionId: questionId };
      } else {
        return a;
      }
    });

    if (!nextQuestion) {
      if (formType !== FormType.Mandatory) {
        setFormSubmitted(true);
        await handleSubmit(newAnswers).catch(() => setFormSubmitted(false));
      } else if (data?.kycForm?.hasUpload === false) {
        // Must be "false", else treat as normal mandatory form
        setScreen('summary');
      } else {
        setScreen('file-upload');
      }
    }
    setAnswers(newAnswers);
    setCurrentQuestion(nextQuestion);
  };

  const recursivelyRemoveAnswer = (questionId: number, result: Answer[]): Answer[] => {
    const currentQuestion = data?.kycForm?.questions.find((q) => q.id === questionId);
    const currentAnswer = currentQuestion?.answers.find(
      (a) => answers.find((an) => an.questionAnswerId === questionId)?.answerId === a.id,
    );

    const subsequentquestionAnswerId = currentAnswer?.subsequentquestionAnswerId;
    const strippedResult = result.map((a) =>
      a.questionAnswerId === questionId
        ? { ...a, questionAnswerId: a.questionAnswerId, answer: '', answerId: null }
        : a,
    );
    if (subsequentquestionAnswerId) {
      return recursivelyRemoveAnswer(subsequentquestionAnswerId, strippedResult);
    }
    return strippedResult;
  };

  const handleEditAnswer = (input: AnswerInput) => {
    const { answer, subsequentquestionAnswerId, answerId } = input;

    const currentAnswer = currentQuestion?.answers.find(
      (a) =>
        a.answerText === answers.find((pa) => pa.questionAnswerId === currentQuestion.id)?.answer,
    );

    let strippedAnswers: Answer[] = [...answers];
    if (currentAnswer?.subsequentquestionAnswerId && !subsequentquestionAnswerId) {
      strippedAnswers = recursivelyRemoveAnswer(currentAnswer.subsequentquestionAnswerId, [
        ...answers,
      ]);
    }

    const newAnswers = strippedAnswers.map((a) =>
      a.questionAnswerId === currentQuestion?.id
        ? { questionAnswerId: currentQuestion?.id, answer, answerId: answerId }
        : a,
    );
    setAnswers(newAnswers);

    if (subsequentquestionAnswerId) {
      const nextQuestion = data?.kycForm?.questions.find(
        (q) => q.id === subsequentquestionAnswerId,
      );
      if (nextQuestion) {
        setCurrentQuestion(nextQuestion);
      }
    } else {
      setScreen('summary');
    }
  };
  const max =
    (data?.kycForm?.questions[data?.kycForm?.questions.length - 1].order ?? 0) +
    (formType === FormType.Mandatory ? 3 : 0);

  const progress =
    screen === 'questions' && currentQuestion
      ? currentQuestion.order
      : screen === 'file-upload'
      ? max - 2
      : screen === 'summary'
      ? max - 1
      : max;

  const editQuestion = (q: KycQuestion) => {
    setScreen('edit-question');
    setCurrentQuestion(q);
  };

  const goBack = () => {
    if (screen === 'summary') {
      if (data?.kycForm?.hasUpload === false) {
        const lastAnsweredQuestionId = [...answers]
          .reverse()
          .find((a) => !!a.answer)?.questionAnswerId;
        const lastAnsweredQuestion = data?.kycForm?.questions.find(
          (q) => q.id === lastAnsweredQuestionId,
        );

        setCurrentQuestion(lastAnsweredQuestion);
        setScreen('questions');
      } else {
        setScreen('file-upload');
      }
    } else if (screen === 'file-upload') {
      const lastAnsweredQuestionId = [...answers]
        .reverse()
        .find((a) => !!a.answer)?.questionAnswerId;
      const lastAnsweredQuestion = data?.kycForm?.questions.find(
        (q) => q.id === lastAnsweredQuestionId,
      );

      setCurrentQuestion(lastAnsweredQuestion);
      setScreen('questions');
    } else if (screen === 'questions') {
      if (!currentQuestion) return;

      let previousQuestionId =
        data?.kycForm?.questions.find((q) => q.order === Math.floor(currentQuestion.order ?? 0) - 1)
          ?.id || 0;

      const currentAnswer = answers.find((a) => a.questionAnswerId === currentQuestion.id);
      if (currentAnswer?.fromQuestionId) {
        previousQuestionId = currentAnswer.fromQuestionId;
      }

      const previousQuestion = data?.kycForm?.questions.find((q) => q.id === previousQuestionId);
      setCurrentQuestion(previousQuestion);
    }
  };

  let prevQuestionTitle =
    answers.find((answer) => {
      return answer.questionAnswerId === currentQuestion?.id;
    })?.prevAnswerTitle ?? '';

  if (prevQuestionTitle) {
    prevQuestionTitle =
      prevQuestionTitle.charAt(0).toLocaleLowerCase() + prevQuestionTitle.slice(1);
  }

  return {
    screen,
    setScreen,
    answers,
    setAnswers,
    currentQuestion,
    setCurrentQuestion,
    prevQuestionTitle,
    loading,
    formSubmitted,
    handleAnswer,
    handleEditAnswer,
    handleSubmit,
    progress,
    maxProgress: max,
    editQuestion,
    goBack,
    formId: data?.kycForm?.id,
    data,
    prologue: data?.kycForm?.prologue,
    formLoading,
  };
};
