import axios, { AxiosError } from 'axios';
import { useState } from 'react';

import { ClientCountry } from '../constants';
import { getAuthBackendUrl } from './getAuthBackendUrl';
import { AuthErrorResponse, Client, Environment, ErrorResponse } from './types';

interface TfaInput {
  otp: string;
  token?: string;
}

interface Params {
  onSuccess: () => void;
  onFailure: (errorCode?: ErrorResponse) => void;
  environment: Environment;
  client: Client;
  clientCountry: ClientCountry;
}

export const useTwoFactorAuthentication = (params: Params) => {
  const [loading, setLoading] = useState(false);

  const tfa = async (input: TfaInput) => {
    try {
      const res = await axios.request<{ isAuthenticated: boolean }>({
        method: 'post',
        url: getAuthBackendUrl(params.environment) + '/standard/tfa',
        data: input,
        withCredentials: true,
        headers: {
          'miraiex-client': params.client,
          'MIRAIEX-FRONTEND-ENV': params.environment,
          ...(params.client === 'app' ? { Authorization: `Bearer ${input.token}` } : {}),
          ...(params.clientCountry ? { 'FIRI-CLIENT-COUNTRY': params.clientCountry } : {}),
        },
      });
      return {
        isAuthenticated: res.data.isAuthenticated,
      };
    } catch (e) {
      const error = e as AxiosError<AuthErrorResponse>;
      return {
        isAuthenticated: false,
        errorCode: error?.response?.data?.name,
        message: error?.response?.data?.message,
      };
    }
  };

  const requestTwoFactor = async (otp: string, token?: string) => {
    setLoading(true);
    const res = await tfa({ otp, token });

    if (res.isAuthenticated) {
      params.onSuccess();
    } else {
      params.onFailure(res.errorCode);
    }

    setLoading(false);
  };

  return {
    request: requestTwoFactor,
    loading,
  };
};
