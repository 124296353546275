import React, { useState } from 'react';

import { DatePickerContext, DatePickerContextProvider } from './context';
import { DatePickerInput } from './DatePickerInput';
import { DatePickerMenu } from './DatePickerMenu';
import { PeriodPickerProps } from './types/date-picker';

export const PeriodPicker = (props: PeriodPickerProps) => {
  return (
    <DatePickerContextProvider
      {...props}
      onSelectDateFrom={props.onSelectDateFrom}
      onSelectDateTo={props.onSelectDateTo}
      isPeriodPicker={true}
      period={props.period}
    >
      <DatePickerContext.Consumer>
        {({ datePickerRef, periodStart, periodEnd }) => (
          <div ref={datePickerRef}>
            <div>
              <DatePickerInput
                {...periodStart}
                periodEndInputValue={periodEnd?.inputValue}
                size={props.size}
                label={props.label ?? 'Velg periode'}
                placeholder={
                  props.inputPlaceholder || 'dd.mm.yyyy - dd.mm.yyyy'
                }
                min={props.minDate}
                max={props.maxDate}
              />
            </div>
            <DatePickerMenu>{props.options}</DatePickerMenu>
          </div>
        )}
      </DatePickerContext.Consumer>
    </DatePickerContextProvider>
  );
};
