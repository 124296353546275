import { ApolloQueryResult } from '@apollo/client';

import { useMarkets } from '../gql';
import { CryptoCurrency, MarketsQueryQuery } from '../gql/gqlTypes';

export enum CryptoCategory {
  'popular' = 'popular',
  'volume24h' = 'volume24h',
  'change24h' = 'change24h',
  'stable' = 'stable',
  'stake' = 'stake',
  'pos' = 'pos',
  'pow' = 'pow',
  'newbie' = 'newbie',
  'all' = 'all',
  'de-fi' = 'de-fi',
  'meme' = 'meme',
  'gaming-web3' = 'gaming-web3',
}
const posCoins: CryptoCurrency[] = [
  CryptoCurrency.Eth,
  CryptoCurrency.Bnb,
  CryptoCurrency.Avax,
  CryptoCurrency.Ada,
  CryptoCurrency.Dot,
  CryptoCurrency.Sol,
  CryptoCurrency.Xrp,
  CryptoCurrency.Matic,
  CryptoCurrency.Xlm,
];

const newbieCoins = [
  CryptoCurrency.Btc,
  CryptoCurrency.Eth,
  CryptoCurrency.Xrp,
  CryptoCurrency.Ada,
];

const defiCoins = [
  CryptoCurrency.Uni,
  CryptoCurrency.Link,
  CryptoCurrency.Ada,
  CryptoCurrency.Avax,
  CryptoCurrency.Dai,
  CryptoCurrency.Bnb,
  CryptoCurrency.Aave,
];

const gamingCoins = [CryptoCurrency.Mana, CryptoCurrency.Sand];

const stakableCoins = [CryptoCurrency.Eth, CryptoCurrency.Ada];
export const memeCoins = [CryptoCurrency.Doge];

const powCoins: CryptoCurrency[] = [CryptoCurrency.Btc, CryptoCurrency.Ltc];
const stableCoins: CryptoCurrency[] = [
  CryptoCurrency.Usdc,
  CryptoCurrency.Dai,
  CryptoCurrency.Usdt,
];

type Data = { [key in CryptoCategory]: MarketsQueryQuery['markets'] };
type SingleData = MarketsQueryQuery['markets'];

export function useCategories(params: { currency: CryptoCurrency }) {
  const { volume24h, change24h } = useCryptoCategories();
  const categories: CryptoCategory[] = [];

  if (volume24h.find((m) => m.baseCurrency?.symbol === params.currency)) {
    categories.push(CryptoCategory.volume24h);
  }

  if (change24h.find((m) => m.baseCurrency?.symbol === params.currency)) {
    categories.push(CryptoCategory.change24h);
  }

  if (posCoins.includes(params.currency)) {
    categories.push(CryptoCategory.pos);
  }
  if (newbieCoins.includes(params.currency)) {
    categories.push(CryptoCategory.newbie);
  }
  if (defiCoins.includes(params.currency)) {
    categories.push(CryptoCategory['de-fi']);
  }
  if (stableCoins.includes(params.currency)) {
    categories.push(CryptoCategory.stable);
  }
  if (memeCoins.includes(params.currency)) {
    categories.push(CryptoCategory.meme);
  }
  if (powCoins.includes(params.currency)) {
    categories.push(CryptoCategory.pow);
  }
  if (stakableCoins.includes(params.currency)) {
    categories.push(CryptoCategory.stake);
  }
  if (gamingCoins.includes(params.currency)) {
    categories.push(CryptoCategory['gaming-web3']);
  }
  return categories;
}

function useCryptoCategories(params: { category: CryptoCategory }): SingleData;
function useCryptoCategories(): Data & {
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<MarketsQueryQuery>>;
};
function useCryptoCategories(params?: { category: CryptoCategory }) {
  const MAX_LENGTH = 4;
  const { data, loading, refetch } = useMarkets({
    includeOtc: true,
    fetchPolicy: 'cache-and-network',
  });

  if (!data) {
    if (params?.category) return [] as SingleData;

    return {
      popular: [],
      stable: [],
      pos: [],
      pow: [],
      change24h: [],
      volume24h: [],
      stake: [],
      newbie: [],
      all: [],
      'de-fi': [],
      meme: [],
      'gaming-web3': [],
      loading: loading,
      refetch,
    } as Data;
  }

  const volume24h = [...data.markets].sort((a, b) => {
    const volA = parseFloat(a.volume || '0') * parseFloat(a.lastPrice || '0');
    const volB = parseFloat(b.volume || '0') * parseFloat(b.lastPrice || '0');

    return volB - volA;
  });
  const change24h = [...data.markets].sort(
    (a, b) => Math.abs(parseFloat(b.change || '0')) - Math.abs(parseFloat(a.change || '0')),
  );

  const popular: MarketsQueryQuery['markets'] = data.markets; // coins are weighted in gql
  const stable: MarketsQueryQuery['markets'] = [];
  const pos: MarketsQueryQuery['markets'] = [];
  const pow: MarketsQueryQuery['markets'] = [];
  const stake: MarketsQueryQuery['markets'] = [];
  const newbie: MarketsQueryQuery['markets'] = [];
  const deFi: MarketsQueryQuery['markets'] = [];
  const meme: MarketsQueryQuery['markets'] = [];
  const gaming: MarketsQueryQuery['markets'] = [];

  data?.markets.forEach((m) => {
    if (posCoins.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      pos.push(m);
    }

    if (powCoins.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      pow.push(m);
    }

    if (stableCoins.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      stable.push(m);
    }
    if (stakableCoins?.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      stake.push(m);
    }
    if (newbieCoins?.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      newbie.push(m);
    }
    if (defiCoins?.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      deFi.push(m);
    }
    if (memeCoins?.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      meme.push(m);
    }
    if (gamingCoins?.includes(m.baseCurrency?.symbol as any as CryptoCurrency)) {
      gaming.push(m);
    }
  });

  const categories: { [key in CryptoCategory]: MarketsQueryQuery['markets'] } = {
    popular: params?.category ? popular : popular.slice(0, MAX_LENGTH),
    stable: params?.category ? stable : stable.slice(0, MAX_LENGTH),
    pos: params?.category ? pos : pos.slice(0, MAX_LENGTH),
    pow: params?.category ? pow : pow.slice(0, MAX_LENGTH),
    change24h: params?.category ? change24h : change24h.slice(0, MAX_LENGTH),
    volume24h: params?.category ? volume24h : volume24h.slice(0, MAX_LENGTH),
    stake: params?.category ? stake : stake.slice(0, MAX_LENGTH),
    newbie: params?.category ? newbie : newbie.slice(0, MAX_LENGTH),
    all: data.markets,
    'de-fi': deFi,
    meme: params?.category ? meme : meme.slice(0, MAX_LENGTH),
    'gaming-web3': params?.category ? gaming : gaming.slice(0, MAX_LENGTH),
  };

  if (params?.category) {
    return categories[params.category];
  }
  return { ...categories, refetch };
}

export { useCryptoCategories };
