import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { BannerArea, BannersQuery } from './../gqlTypes';

const query = gql`
  query Banners {
    banners {
      id
      area
      type
      no {
        title
        content
      }
      en {
        title
        content
      }
      da {
        title
        content
      }
      irremovable
    }
  }
`;

export const useBanners = (area?: BannerArea, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<BannersQuery>(query, options);
  const { i18n } = useLingui();

  const bannersFiltered = area
    ? data?.banners
        .filter((banner) => banner?.area === area)
        .map((banner: any) => ({
          ...banner,
          data: banner?.[i18n.locale],
        }))
    : [];

  return {
    banners: bannersFiltered || [],
    refetch,
    loading,
    error,
  };
};
