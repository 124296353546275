import React from 'react';

import { WebTypography } from '../../01-atoms';

export const JsonViewer = (props: {
  header: React.ReactNode;
  json: object;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col items-center">
      <WebTypography weight="medium" size="md" className="mb-4">
        {props.header}
      </WebTypography>
      <div className="overflow-auto w-full mb-4">
        <WebTypography size="xs">
          <pre>{JSON.stringify(props.json, null, 4)}</pre>
        </WebTypography>
      </div>
      <div>{props.children}</div>
    </div>
  );
};
