import {
  DepositType,
  SearchUnion,
  TradeType,
  TransactionsMiscTypes,
} from '@firi/shared/gql/gqlTypes';
import { t } from '@lingui/macro';

export const getTransactionMiscLabel = (miscTransactionType: TransactionsMiscTypes) => {
  switch (miscTransactionType) {
    case TransactionsMiscTypes.Transfer:
      return t`User transfer`;
    case TransactionsMiscTypes.Voucher:
      return t`Voucher`;
    case TransactionsMiscTypes.Bonus:
      return t`Bonus`;
    case TransactionsMiscTypes.Donation:
      return t`Donation`;
    case TransactionsMiscTypes.AffiliateCommision:
      return t`Affiliate commision`;
    case TransactionsMiscTypes.FeebackBonus:
      return t`Feeback bonus`;
    case TransactionsMiscTypes.AffiliateBonus:
      return t`Affiliate bonus`;
    case TransactionsMiscTypes.WelcomeBonus:
      return t`Welcome bonus`;
    case TransactionsMiscTypes.ChristmasBonus:
      return t`Christmas Bonus`;
    case TransactionsMiscTypes.Prize:
      return t`Prize`;
    case TransactionsMiscTypes.Refund:
      return t`Refund`;
    case TransactionsMiscTypes.InternalTransfer:
      return t`Internal transfer`;
    case TransactionsMiscTypes.Stake:
      return t`Staking`;
    case TransactionsMiscTypes.Unstake:
      return t`Unstake`;
    case TransactionsMiscTypes.StakingReward:
      return t`Reward from staking`;

    default:
      return t`Unknown`;
  }
};

export const getTransactionLabel = (transaction: SearchUnion) => {
  if (transaction.__typename === 'Trade') {
    if (transaction.tradeType === TradeType.Buy) {
      return t`Bought`;
    } else if (transaction.tradeType === TradeType.Sell) return t`Sold`;
    else {
      return t`Unknown`;
    }
  } else if (transaction.__typename === 'TransactionMisc') {
    return getTransactionMiscLabel(transaction.type);
  } else if (transaction.__typename === 'TransferDeposit') {
    if (transaction.isFiat) {
      switch (transaction.depositType) {
        case DepositType.BankDeposit:
          return t`Deposit from bank`;
        case DepositType.SchemeDeposit:
          return t`Deposit with card`;
        case DepositType.VippsDeposit:
          return t`Deposit with Vipps`;
        case DepositType.MobilepayDeposit:
          return t`Deposit with MobilePay`;
        case DepositType.GooglePayDeposit:
          return t`Deposit with Google Pay`;
        case DepositType.ApplePayDeposit:
          return t`Deposit with Apple Pay`;
        case DepositType.Deposit:
          return t`Deposit`;
        default:
          return t`Deposit`;
      }
    }
    return t`Received`;
  } else if (transaction.__typename === 'TransferWithdraw') {
    return transaction.isFiat ? t`Withdraw to bank` : t`Sent`;
  }
  return t`Unknown`;
};
