import { getCookieByName } from './getCookieByName';

const APPSFLYER_TEMPLATE_LINK = 'https://firi.onelink.me/ZT6F';

export const getAppDownloadUrl = (options: {
  platform?: 'ios' | 'android';
  deviceId?: string;
  locale?: string;
}) => {
  if (options.deviceId && checkForAnyCookieAttributions()) {
    return getAppsflyerDownloadLink(options.deviceId);
  }
  if (options.platform === 'ios') {
    return getAppStoreDownload(options.locale);
  }
  if (options.platform === 'android') {
    return getPlayStoreDownload(options.locale);
  }
  return 'https://firi.com/app-link';
};

const getAppStoreDownload = (locale: string | undefined) => {
  if (locale === 'no') {
    return 'https://apps.apple.com/no/app/miraiex/id1542108249';
  }
  if (locale === 'dk') {
    return 'https://apps.apple.com/dk/app/miraiex/id1542108249';
  }
  return 'https://apps.apple.com/no/app/miraiex/id1542108249';
};

const getPlayStoreDownload = (locale: string | undefined) => {
  if (locale === 'no') {
    return 'https://play.google.com/store/apps/details?id=com.mirai.miraiex';
  }
  if (locale === 'dk') {
    return 'https://play.google.com/store/apps/details?id=com.mirai.miraiex&hl=da&gl=DK';
  }
  return 'https://play.google.com/store/apps/details?id=com.mirai.miraiex';
};

/**
 * It leverages cookie so only works in browser
 * Important that <a> doesn't use target=_blank, since the weakens the attribution tracking
 */
const getAppsflyerDownloadLink = (deviceId?: string) => {
  if (deviceId) {
    let appsflyerLink = `${APPSFLYER_TEMPLATE_LINK}?deep_link_value=web&deep_link_sub5=${deviceId}`;
    const { utmSource, utmMedium, utmCampaign, utmContent, referrer } = trackingAttributions();

    if (utmSource) {
      appsflyerLink += `&deep_link_sub1=${utmSource}`;
    }
    if (utmMedium) {
      appsflyerLink += `&deep_link_sub2=${utmMedium}`;
    }
    if (utmCampaign) {
      appsflyerLink += `&deep_link_sub3=${utmCampaign}`;
    }
    if (utmContent) {
      appsflyerLink += `&deep_link_sub4=${utmContent}`;
    }
    if (referrer) {
      appsflyerLink += `&deep_link_sub7=${referrer}`;
    }

    // This link is smart enough to auto redirect to correct country app store
    return appsflyerLink;
  }
  return APPSFLYER_TEMPLATE_LINK;
};

export const trackingAttributions = () => {
  return {
    referrer: getCookieByName('referrer'),
    utmSource: getCookieByName('utm_source'),
    utmMedium: getCookieByName('utm_medium'),
    utmCampaign: getCookieByName('utm_campaign'),
    utmContent: getCookieByName('utm_content'),
  };
};

const checkForAnyCookieAttributions = () => {
  return (
    Boolean(getCookieByName('referrer')) ||
    Boolean(getCookieByName('utm_source')) ||
    Boolean(getCookieByName('utm_medium')) ||
    Boolean(getCookieByName('utm_campaign')) ||
    Boolean(getCookieByName('utm_content'))
  );
};
