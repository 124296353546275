import '../styles/globals.css';

import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@firi/shared';
import { ClientCountry } from '@firi/shared/constants';
import { DefaultMeta } from 'components/DefaultMeta';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { HrefLangProps } from 'components/HrefLang';
import { LanguageProvider } from 'contexts/LanguageContext';
import { urlFor } from 'helpers/image';
import { setLocaleCookies } from 'helpers/setLocaleCookies';
import { useStoreTrackingCookies } from 'helpers/trackingAttributions';
import { useAffiliateCookie } from 'hooks/useAffiliateCookie';
import { useInitializeAmplitude } from 'hooks/useInitializeAmplitude';
import { sharedApolloClientProps } from 'lib/apollo/client';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useMemo } from 'react';
import { useEffect } from 'react';

const CookieConsentNoSsr = dynamic<{}>(
  () => import('components/CookieConsent').then((mod) => mod.CookieConsent),
  {
    ssr: false,
  },
);

const ModalControllerWithNoSSR = dynamic<{}>(
  () => import('components/Modal/ModalController').then((mod) => mod.ModalController),
  {
    ssr: false,
  },
);

const HrefLang = dynamic<HrefLangProps>(
  () => import('components/HrefLang').then((mod) => mod.HrefLang),
  {
    ssr: false,
  },
);

const RiverSecurityScriptNoSSR = dynamic<HrefLangProps>(
  () => import('components/RiverSecurityScript').then((mod) => mod.RiverSecurityScript),
  {
    ssr: false,
  },
);

function App({ Component, pageProps, router }: AppProps) {
  const { showHeader = true, showFooter = true, alternateSlugs } = pageProps;
  const nextRouter = useRouter();
  useInitializeAmplitude();
  useAffiliateCookie();
  useStoreTrackingCookies();

  useEffect(() => {
    if (router.locale) {
      setLocaleCookies(router.locale);
    }
  }, [router.locale]);

  const client = useMemo(() => {
    const country: ClientCountry = router.locale === 'dk' ? 'DK' : 'NO';
    return createApolloClient({
      ...sharedApolloClientProps,
      locale: router.locale || 'no',
      clientCountry: country,
    });
  }, [router.locale]);

  const alternate =
    alternateSlugs ||
    router.locales?.reduce((acc, cur) => (acc = { ...acc, [cur]: router.query }), {});

  const imageUrl =
    pageProps.data?.openGraphImage?.asset && urlFor(pageProps.data.openGraphImage.asset)?.url();

  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <Script
          strategy="afterInteractive"
          id="gtm"
          key="gtm"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-5FJW5SM');
          `,
          }}
        />
      )}
      <ApolloProvider client={client}>
        <LanguageProvider>
          {nextRouter.pathname !== '/investor' && (
            <DefaultMeta
              alternate={alternate}
              title={pageProps.data?.title}
              description={pageProps.data?.description}
              keywords={pageProps.data?.keywords}
              image={imageUrl}
            />
          )}
          <HrefLang alternateSlugs={alternate} />
          <div className="flex flex-col">
            {showHeader && <Header alternateSlugs={alternateSlugs} />}
            <Component {...pageProps} />
            <RiverSecurityScriptNoSSR />
            {showFooter && <Footer alternateSlugs={alternateSlugs} />}
          </div>
          {nextRouter.pathname !== '/investor' && <CookieConsentNoSsr />}
          <ModalControllerWithNoSSR />
        </LanguageProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
