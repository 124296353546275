import { gql, useQuery } from '@apollo/client';

import { ReferralsQuery } from './../gqlTypes';

const QUERY = gql`
  query Referrals {
    affiliate {
      referrals {
        code
        count {
          completed
          pending
        }
        payout {
          received
          pending
          currency
        }
        referrals {
          id
          name
          status
          payout {
            currency
            amount
            payoutAmount
            payoutCurrency
            paidAt
            creditedAt
          }
        }
      }
    }
  }
`;

export const useReferrals = () => {
  return useQuery<ReferralsQuery>(QUERY, {
    fetchPolicy: 'cache-and-network',
  });
};
