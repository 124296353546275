import { Environment } from './auth/types';

export class ConfigManager {
  public credentials;

  constructor(environment: Environment) {
    this.credentials = {
      customerIo: {
        siteId: ['prod', 'beta'].includes(environment)
          ? 'cbcc106c36400d9f3266'
          : '540d6687bc6dcbacc08e',
        apiKey: ['prod', 'beta'].includes(environment)
          ? '72e77f93e78fd05d09e7'
          : 'df6439d7525c3e78bfe1',
      },
      amplitude: {
        proxyServer: ['prod', 'beta'].includes(environment)
          ? 'https://tm-apml.firi.com/2/httpapi'
          : 'https://tm-apml.testfiri.com/2/httpapi',
        apiKey: ['prod', 'beta'].includes(environment)
          ? 'f4d8f87ae79dece4260e21c91bc5591d'
          : 'f6fd78b01343b4d9136ddbf59822563c',
      },
      appsflyer: {
        devKey: 'DmssAhS6i8pvdYnCYJ3HZ7',
        iOSAppId: '1542108249',
      },
    };
  }

  get customerIo() {
    return this.credentials.customerIo;
  }

  get amplitude() {
    return this.credentials.amplitude;
  }

  get appsflyer() {
    return this.credentials.appsflyer;
  }
}
