import { getPlatformLink } from '@firi/shared';
import { firiMerge } from '@firi/shared/styles';
import { t, Trans } from '@lingui/macro';
import ContentWrapper from 'components/ContentWrapper';
import type { DownloadAppProps } from 'components/DownloadAppButton';
import { Grid, GridItem } from 'components/Grid';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { alternateSlug } from '../helpers/url';
import Logo from '../shared/svg/logo-white.svg';
import { Button } from './Button';
import { LanguageSelect } from './LanguageSelect';
import { NavItems } from './NavItems';
import { SoMeLinks } from './SoMeLinks';

const BLACKLIST_STICKY_FOOTER = ['/signup', '/affiliate', '/investor', '/dealflow'];

const FooterNoSsr = dynamic<{}>(
  () => import('components/FooterBanner').then((mod) => mod.FooterBanner),
  {
    ssr: false,
  },
);

const DownloadAppButtonNoSsr = dynamic<DownloadAppProps>(
  () => import('components/DownloadAppButton').then((mod) => mod.DownloadAppButton),
  {
    ssr: false,
  },
);

export interface FooterProps {
  alternateSlugs: alternateSlug;
}

export const Footer = ({ alternateSlugs }: FooterProps) => {
  const router = useRouter();

  const isDKCampaign =
    router.pathname === '/campaign/[slug]' && router.query.slug === 'kampagne-dk';

  let isApp = router.query.source === 'firi-app';

  if (typeof window !== 'undefined') {
    const storedSource = window.sessionStorage.getItem('source');
    if (storedSource === 'firi-app') {
      isApp = true;
    }
  }

  useEffect(() => {
    if (isApp && typeof sessionStorage !== 'undefined') {
      sessionStorage?.setItem('source', 'firi-app');
    }
  }, [isApp]);

  return (
    <footer className="bg-firi-blue pt-10 pb-24 md:py-10">
      <div className="flex justify-center mb-10 pt-28 md:pt-0 pb-16 md:pb-10 border-b border-b-white px-4 lg:px-9">
        <FooterNoSsr />
      </div>
      {!isDKCampaign && (
        <ContentWrapper>
          <Grid>
            <GridItem className="hidden md:block lg:col-span-2">
              <Logo alt="Firi logo" width="89" height="24" />
            </GridItem>
            <GridItem className="lg:col-span-6 mb-8 md:mb-0">
              <nav className="text-white">
                <Grid className="lg:!grid-cols-3 !gap-4">
                  <NavItems placement="Footer" />
                </Grid>
              </nav>
            </GridItem>
            <div className="col-span-1 md:col-span-full lg:col-span-2">
              <SoMeLinks locale={router.locale} />
            </div>
            <div className="col-span-1 md:col-span-full lg:col-span-2 flex items-end md:items-start">
              <LanguageSelect alternateSlugs={alternateSlugs} />
            </div>
          </Grid>
        </ContentWrapper>
      )}

      {!BLACKLIST_STICKY_FOOTER.includes(router.pathname) && (
        <div
          className={firiMerge(
            'fixed bottom-0 right-0 left-0 bg-white p-4 justify-center items-center',
            isApp ? 'hidden' : 'flex md:hidden',
          )}
        >
          <div className="mr-4">
            <a aria-label="To get started" href={getPlatformLink({ link: 'signup' })}>
              <Button skin="outlined" aria-label="Get started" eventAction="Get started">
                <Trans>Get started</Trans>
              </Button>
            </a>
          </div>
          <div>
            <DownloadAppButtonNoSsr text={t`Download our app`} />
          </div>
        </div>
      )}
    </footer>
  );
};
