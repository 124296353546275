import { gql, useQuery } from '@apollo/client';

import { KycFormFragment } from '../fragments';
import { FormType, KycFormQuery, KycFormQueryVariables } from '../gqlTypes';

const query = gql`
  query KycForm($input: KycFormInput!) {
    kycForm(input: $input) {
      ...KycFormFragment
    }
  }
  ${KycFormFragment}
`;

export const useKycForm = (params: { formType: FormType }) =>
  useQuery<KycFormQuery, KycFormQueryVariables>(query, {
    variables: { input: { formTypeId: params.formType } },
  });
